import {
  extensionAnalyticsActions,
  extensionAnalyticsMutations,
  extensionAnalyticsGetters
} from './types'

import { sdk } from 'Services/shelfNetworkSdk'
import { LoneSdkCall } from 'Utils/LoneSdkCall'

import { ANALYTICS_PAGE_LIMIT, ANALYTICS_DEFAULT_SORT } from '../constants'

const loneSdkCall = new LoneSdkCall()

export default {
  async [extensionAnalyticsActions.LOAD_LIST] ({ commit }, opts = {}) {
    commit(extensionAnalyticsMutations.SET_IS_LOADING, true)
    commit(extensionAnalyticsMutations.SET_LIST_OPTS, opts)

    const { data } = await loneSdkCall.takeLatest(
      sdk.copartBackOffice.getIdentitiesStatistics({
        query: createAnalyticsQuery(opts)
      })
    )

    commit(extensionAnalyticsMutations.SET_LIST, data)
    commit(extensionAnalyticsMutations.SET_PAGE_NUMBER, 0)

    commit(extensionAnalyticsMutations.SET_IS_LOADING, false)
  },

  async [extensionAnalyticsActions.LOAD_MORE] ({ commit, getters }) {
    if (getters[extensionAnalyticsGetters.PAGE_NUMBER] === null) return

    commit(extensionAnalyticsMutations.SET_IS_NEXT_LOADING, true)

    const pageNumber = getters[extensionAnalyticsGetters.PAGE_NUMBER] + 1
    const { data } = await loneSdkCall.takeLatest(
      sdk.copartBackOffice.getIdentitiesStatistics({
        query: createAnalyticsQuery({
          ...getters[extensionAnalyticsGetters.LIST_OPTS],
          page: { number: pageNumber }
        })
      })
    )

    commit(extensionAnalyticsMutations.PUSH_LIST, data)
    commit(
      extensionAnalyticsMutations.SET_PAGE_NUMBER,
      data.length < ANALYTICS_PAGE_LIMIT ? null : pageNumber
    )

    commit(extensionAnalyticsMutations.SET_IS_NEXT_LOADING, false)
  },

  async [extensionAnalyticsActions.LOAD_MANAGED_LOGINS] ({ commit }) {
    const { data: config } = await sdk.copartBackOffice.getConfig({
      query: {
        // HACK: Hardcode version for admin panel
        filter: { version: '333.0.0' }
      }
    })

    commit(
      extensionAnalyticsMutations.SET_MANAGED_LOGINS,
      config.allowedToAssignTo
    )
  },

  [extensionAnalyticsActions.SET_IS_LOADING] ({ commit }, value) {
    commit(extensionAnalyticsMutations.SET_IS_LOADING, value)
  }
}

function createAnalyticsQuery (opts) {
  return {
    ...opts,
    page: {
      ...(opts.page || {}),
      limit: ANALYTICS_PAGE_LIMIT
    },
    sort: opts.sort || ANALYTICS_DEFAULT_SORT,
    include: ['identity', 'identity.basics']
  }
}
