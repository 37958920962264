import { extensionAnalyticsGetters } from './types'
import { IdentityStatistics } from 'Models/IdentityStatistics'

export default {
  [extensionAnalyticsGetters.IS_LOADING]: state => state.isLoading,
  [extensionAnalyticsGetters.IS_NEXT_LOADING]: state => state.isNextLoading,

  [extensionAnalyticsGetters.LIST]: state => state.list
    .map(item => new IdentityStatistics(item)),
  [extensionAnalyticsGetters.LIST_OPTS]: state => state.listOpts,
  [extensionAnalyticsGetters.PAGE_NUMBER]: state => state.pageNumber,

  [extensionAnalyticsGetters.MANAGED_LOGINS]: state => state.managedLogins,
}
