import { extensionAnalyticsMutations } from './types'

export default {
  [extensionAnalyticsMutations.SET_IS_LOADING] (state, value) {
    state.isLoading = value
  },

  [extensionAnalyticsMutations.SET_IS_NEXT_LOADING] (state, value) {
    state.isNextLoading = value
  },

  [extensionAnalyticsMutations.SET_LIST] (state, value) {
    state.list = value
  },

  [extensionAnalyticsMutations.SET_LIST_OPTS] (state, value) {
    state.listOpts = value
  },

  [extensionAnalyticsMutations.PUSH_LIST] (state, value) {
    state.list.push(...value)
  },

  [extensionAnalyticsMutations.SET_PAGE_NUMBER] (state, value) {
    state.pageNumber = value
  },

  [extensionAnalyticsMutations.SET_MANAGED_LOGINS] (state, value) {
    state.managedLogins = value
  },
}
